<template>
  <div class="content flex" :class="$attrs.class">
    <app-icon-small-logo />
    <p class="ml-2 font-bold flex-wrap">
      {{ title }}
    </p>
  </div>
  <p class="mt-4 font-light ml-8">{{ desc }}</p>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  props: {
    title: {
      type: String
    },
    desc: {
      type: String
    }
  },
  methods: {},
};
</script>